<template>
    <div class="hsCom7">
        <div class="hc7Box">
            <!-- 标题 -->
            <div class="hc7Title wow animate__animated animate__slideInUp">
                <p class="hc7Big">案例展示</p>
                <p class="hc7Small">CASE SHOWS</p>
            </div>
            <!-- 图片列表 -->
            <div class="hc7Imgs wow animate__animated animate__slideInUp">
                <ul>
                    <li class="hc7Li" v-for="el in list" :key="el.id" @click.prevent="goDetail(el)">
                        <img v-lazy="el.little_image" class="hc7Img" />
                        <div class="h7Mask" v-if="el.qrcode || el.logo">
                            <img v-if="el.qrcode" v-lazy="el.qrcode" class="h7mCode" />
                            <img v-else v-lazy="el.logo" class="h7mCode" />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "HsCom7",
    data() {
        return {
            list: [],
        };
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            let { data } = await axios.get("/api/project/marketing");
            this.list = data;
        },
        goDetail({ id, ueditorData, project_url }) {
            if (ueditorData == "" || ueditorData == "<p><br></p>" || ueditorData == null) {
                if (!project_url) return;
                if (project_url.indexOf("http") >= 0 || project_url.indexOf("https") >= 0) {
                    window.open(project_url);
                } else {
                    window.open("http://" + project_url);
                }
                return;
            }
            // 点击后的回调
            this.$emit("callBack");
        },
    },
};
</script>

<style lang="less" scoped>
.hsCom7 {
    min-width: 1423px;
    height: 550px;
    margin-top: 50px;
    .hc7Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 文字
        .hc7Title {
            width: 50%;
            height: 80px;
            margin: 0 auto;
            text-align: center;
            .hc7Big {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 40px;
                font-weight: 900;
            }
            .hc7Small {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-size: 30px;
            }
        }
        // 图片列表
        .hc7Imgs {
            width: 100%;
            height: 350px;
            margin-top: 50px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                .hc7Li {
                    width: 32%;
                    height: 100%;
                    border-radius: 15px;
                    overflow: hidden;
                    position: relative;
                    .hc7Img {
                        width: 100%;
                        height: 100%;
                        transition: 0.5s;
                        object-fit: cover;
                    }
                    .h7Mask {
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.5);
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition: 0.5s;
                        opacity: 0;
                        cursor: pointer;
                        .h7mCode {
                            width: 120px;
                            height: 120px;
                            border-radius: 5px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            object-fit: contain;
                            // display: block;
                            // margin: 0 auto;
                            // margin-top: 85px;
                        }
                        .h7m1 {
                            width: 100%;
                            height: 30px;
                            line-height: 30px;
                            text-align: center;
                            color: white;
                            margin-top: 20px;
                        }
                    }
                }
                .hc7Li:hover {
                    .hc7Img {
                        transform: scale(1.1);
                    }
                    .h7Mask {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
</style>
