<template>
    <div class="hc14Box">
        <div class="hbBox1" v-if="getAdvData(14).children" :style="`background-image: url(${getAdvData(14).children[0].image})`">
            <a :href="getAdvData(14).children[0].url" target="_blank" rel="noopener noreferrer">
                <div class="hc1Box wow animate__animated animate__fadeInUp">
                    <!-- 标题 -->
                    <div class="hc1Title">
                        <p class="hc1Big">威有科技·营销官网</p>
                        <p class="hc1Small">天生具有营销力的网站</p>
                    </div>
                    <!-- 列表 -->
                    <div class="hc1List">
                        <ul>
                            <li>可靠盈利系统</li>
                            <li>提升询盘转化率</li>
                            <li>搜索排名靠前</li>
                        </ul>
                    </div>
                    <!-- 图片 -->
                    <div class="hc1Imgs">
                        <img :src="getAdvData(14).children[0].children.find(e => e.id == 247).image" />
                        <img :src="getAdvData(14).children[0].children.find(e => e.id == 248).image" />
                        <img :src="getAdvData(14).children[0].children.find(e => e.id == 249).image" />
                    </div>
                </div>
            </a>
        </div>
        <div class="hbBox2">
            <div class="hc2Box">
                <!-- 标题 -->
                <div class="hc2Title">
                    <p class="hc2Big">威有网站建设服务</p>
                    <p class="hc2Small">MARKETING SERVICES</p>
                </div>
                <!-- 图标列表 -->
                <div class="hc2List wow animate__animated animate__fadeInUp">
                    <ul>
                        <li class="hc2Li">
                            <div class="hc2LIcon">
                                <img src="http://iv.okvu.cn/vugw/img/hc1121.png" alt="" />
                            </div>
                            <div class="hc2LText">自由个性化的网站定制服务</div>
                        </li>
                        <li class="hc2Li">
                            <div class="hc2LIcon">
                                <img src="http://iv.okvu.cn/vugw/img/hc1122.png" alt="" />
                            </div>
                            <div class="hc2LText">一对一 专人专项协作策划网站</div>
                        </li>
                        <li class="hc2Li">
                            <div class="hc2LIcon">
                                <img src="http://iv.okvu.cn/vugw/img/hc1123.png" alt="" />
                            </div>
                            <div class="hc2LText">售后提供多项免费维护</div>
                        </li>
                        <li class="hc2Li">
                            <div class="hc2LIcon">
                                <img src="http://iv.okvu.cn/vugw/img/hc1124.png" alt="" />
                            </div>
                            <div class="hc2LText">支持PC+APP+WAP+微信四合一网站开发</div>
                        </li>
                        <li class="hc2Li">
                            <div class="hc2LIcon">
                                <img src="http://iv.okvu.cn/vugw/img/hc1125.png" alt="" />
                            </div>
                            <div class="hc2LText">支持ASP、PHP、.Net等多种语言开发</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="hbBox3">
            <div class="h3Box wow animate__animated animate__fadeInUp">
                <div class="h3Left">
                    <p class="h3l1">可靠盈利系统</p>
                    <p class="h3l2">威有科技营销网站如何为企业盈利？</p>
                </div>
                <div class="h3Right">
                    <ul>
                        <li class="h3rLi" v-for="item in 6" :key="item">
                            <div class="h3rBox">
                                <div class="hrb1">
                                    <img src="http://iv.okvu.cn/vugw/img/hc1431.png" alt="" />
                                </div>
                                <div class="hrb2">
                                    <p class="h21">精准盈利模式定位</p>
                                    <p class="h22">精准盈利模式定位，明确企业盈利方向，定向吸引客户浏览</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="hbBox4">
            <div class="hb4Top wow animate__animated animate__fadeInUp">
                <p class="h4t1">提升询盘转化率</p>
                <p class="t4l2">营销力就是成交率</p>
            </div>
            <div class="hb4List wow animate__animated animate__fadeInUp">
                <ul>
                    <li class="htsLi" v-for="item in 4" :key="item">
                        <div class="hlTs">
                            <p class="ht1">跳出率降低60%</p>
                            <p class="ht2">
                                结合威有搜索引擎和HTML+DIV布局技术，赋予网站超快访问速度。
                                精心制作营销内容，让客户一见倾心，极致降低网站跳出率。
                            </p>
                        </div>
                        <div class="hlImgs">
                            <img src="http://iv.okvu.cn/vugw/img/hc1451.png" alt="" class="hiBg" />
                            <img src="http://iv.okvu.cn/vugw/img/hc1452.jpg" alt="" class="hiImg" />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="hbBox5">
            <div class="h5Box">
                <ul>
                    <li class="h5bLis" v-for="item in 4" :key="item">
                        <div class="hlBox">
                            <div class="hb1">
                                <img src="http://iv.okvu.cn/vugw/img/hc1461.png" alt="" />
                            </div>
                            <p class="hb2">SEO关键词挖掘及布局</p>
                            <p class="hb3">SEO keyword mining and layout</p>
                        </div>
                    </li>
                    <li class="h5bLib">
                        <img src="http://iv.okvu.cn/vugw/img/hc1462.png" alt="" />
                    </li>
                </ul>
            </div>
        </div>
        <div class="hbBox6">
            <div class="hb6Box">
                <div class="hb6Top">
                    <p class="h6t1">营销型网站，与品牌型网站、全网型官网区别</p>
                    <p class="h6t2">
                        营销型网站功利性要很强，会牺牲掉传统官网、品牌网站慢条斯理结构，
                        <br />
                        对于大型企业、知名品牌公司，利弊选择需要慎重
                    </p>
                </div>
                <div class="hb6Tb">
                    <table cellpadding="0" cellspacing="0" border="0" width="100%">
                        <tbody>
                            <tr class="hb6Tr1">
                                <td class="h61td"></td>
                                <td class="h61td">营销型网站</td>
                                <td class="h61td">品牌型网站</td>
                                <td class="h61td">全网型网站</td>
                            </tr>
                            <tr class="hb6Tr">
                                <td class="h6td" style="font-size: 20px">主要目的</td>
                                <td class="h6td">卖出产品(或服务)、期望成交</td>
                                <td class="h6td">传播产品(或服务)、培养教育用户</td>
                                <td class="h6td">全方位展示、所有网络渠道的最终权威站点</td>
                            </tr>
                            <tr class="hb6Tr">
                                <td class="h6td">结构和信息完整性</td>
                                <td class="h6td">
                                    <div class="h6d">
                                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h6dImg" />
                                    </div>
                                </td>
                                <td class="h6td">
                                    <div class="h6d">
                                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h6dImg" />
                                    </div>
                                </td>
                                <td class="h6td">
                                    <div class="h6d2">
                                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h6dImg" />
                                    </div>
                                </td>
                            </tr>
                            <tr class="hb6Tr">
                                <td class="h6td">内容严肃</td>
                                <td class="h6td">
                                    <div class="h6d">
                                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h6dImg" />
                                    </div>
                                </td>
                                <td class="h6td">
                                    <div class="h6d">
                                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h6dImg" />
                                    </div>
                                </td>
                                <td class="h6td">
                                    <div class="h6d2">
                                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h6dImg" />
                                    </div>
                                </td>
                            </tr>
                            <tr class="hb6Tr">
                                <td class="h6td">品牌宣传效果</td>
                                <td class="h6td">
                                    <div class="h6d3">
                                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h6dImg" />
                                    </div>
                                </td>
                                <td class="h6td">
                                    <div class="h6d2">
                                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h6dImg" />
                                    </div>
                                </td>
                                <td class="h6td">
                                    <div class="h6d4">
                                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h6dImg" />
                                    </div>
                                </td>
                            </tr>
                            <tr class="hb6Tr">
                                <td class="h6td">产品展示效果</td>
                                <td class="h6td">
                                    <div class="h6d2">
                                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h6dImg" />
                                    </div>
                                </td>
                                <td class="h6td">
                                    <div class="h6d5">
                                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h6dImg" />
                                    </div>
                                </td>
                                <td class="h6td">
                                    <div class="h6d4">
                                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h6dImg" />
                                    </div>
                                </td>
                            </tr>
                            <tr class="hb6Tr">
                                <td class="h6td">创意表现</td>
                                <td class="h6td">
                                    <div class="h6d4">
                                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h6dImg" />
                                    </div>
                                </td>
                                <td class="h6td">
                                    <div class="h6d2">
                                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h6dImg" />
                                    </div>
                                </td>
                                <td class="h6td">
                                    <div class="h6d4">
                                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h6dImg" />
                                    </div>
                                </td>
                            </tr>
                            <tr class="hb6Tr">
                                <td class="h6td">SEO优化程度</td>
                                <td class="h6td">
                                    <div class="h6d2">
                                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h6dImg" />
                                    </div>
                                </td>
                                <td class="h6td">
                                    <div class="h6d4">
                                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h6dImg" />
                                    </div>
                                </td>
                                <td class="h6td">
                                    <div class="h6d5">
                                        <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="h6dImg" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <HsCom8></HsCom8>
        <HsCom7></HsCom7>
    </div>
</template>

<script>
import HsCom7 from "./HsCom7.vue";
import HsCom8 from "./HsCom8.vue";

export default {
    name: "HsContent14",
    inject: ["getAdvData"],
    components: {
        HsCom7,
        HsCom8,
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.hc14Box {
    width: 100%;
    margin-top: 78px;
    .hbBox1 {
        min-width: 1423px;
        height: 500px;

        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 100%;
        .hc1Box {
            width: 1280px;
            height: 100%;
            margin: 0 auto;
            // 标题
            .hc1Title {
                width: 40%;
                height: 100px;
                margin: 0 auto;
                text-align: center;
                color: white;
                font-weight: 900;
                line-height: 50px;
                .hc1Big {
                    width: 100%;
                    height: 50px;
                    font-size: 40px;
                }
                .hc1Small {
                    width: 100%;
                    height: 50px;
                    font-size: 30px;
                }
            }
            // 列表
            .hc1List {
                width: 50%;
                height: 50px;
                margin: 0 auto;
                margin-top: 20px;
                text-align: center;
                line-height: 50px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    li {
                        width: 20%;
                        height: 50px;
                        font-size: 17px;
                        border-radius: 10px;
                        box-shadow: 1px 1px 10px 1px #eee;
                        color: white;
                    }
                    li:hover {
                        background-color: rgb(68, 163, 180);
                    }
                }
            }
            // 图片
            .hc1Imgs {
                width: 60%;
                height: 300px;
                margin: 0 auto;
                margin-top: 5px;
                img {
                    width: 33.33%;
                    padding-top: 65px;
                }
            }
        }
    }
    .hbBox2 {
        min-width: 1423px;
        height: 500px;
        margin-top: 20px;
        .hc2Box {
            width: 1280px;
            height: 100%;
            margin: 0 auto;
            // 标题
            .hc2Title {
                width: 50%;
                height: 80px;
                margin: 0 auto;
                .hc2Big {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    font-size: 40px;
                    font-weight: 900;
                }
                .hc2Small {
                    width: 100%;
                    height: 30px;
                    font-size: 20px;
                    line-height: 30px;
                    text-align: center;
                }
            }
            // 图标列表
            .hc2List {
                width: 100%;
                height: 300px;
                margin-top: 50px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    .hc2Li {
                        width: 19%;
                        height: 100%;
                        text-align: center;
                        .hc2LIcon {
                            width: 150px;
                            height: 50%;
                            border-radius: 50%;
                            margin: 0 auto;
                            margin-top: 20px;
                            border: 1px solid white;
                            box-shadow: 1px 1px 10px 1px gainsboro;
                            transition: 0.5s;
                            img {
                                width: 40%;
                                height: 40%;
                                margin-top: 50px;
                            }
                        }
                        .hc2LText {
                            width: 200px;
                            height: 50px;
                            margin: 0 auto;
                            margin-top: 30px;
                            font-size: 18px;
                        }
                    }
                    .hc2Li:hover {
                        .hc2LIcon {
                            transform: rotate(360deg);
                        }
                    }
                }
            }
        }
    }
    .hbBox3 {
        width: 100%;
        height: 544px;
        background: url("http://iv.okvu.cn/vugw/img/gb2.jpg");
        .h3Box {
            max-width: 1280px;
            height: 100%;
            margin: 0 auto;
            overflow: hidden;
            .h3Left {
                width: 340px;
                height: 300px;
                float: left;
                margin-left: 100px;
                margin-top: 100px;
                .h3l1 {
                    width: 100%;
                    height: 135px;
                    line-height: 135px;
                    font-size: 48px;
                    font-weight: 900;
                }
                .h3l2 {
                    width: 100%;
                    height: 110px;
                    line-height: 55px;
                    font-size: 30px;
                    color: gray;
                }
            }
            .h3Right {
                width: 670px;
                height: 354px;
                float: right;
                margin-top: 100px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    .h3rLi {
                        width: 327px;
                        height: 113px;
                        background-color: #fff;
                        border-radius: 10px;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        .h3rBox {
                            width: 90%;
                            height: 85%;
                            margin: 0 auto;
                            margin-top: 2%;
                            .hrb1 {
                                width: 85px;
                                height: 85px;
                                float: left;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .hrb2 {
                                width: 203px;
                                height: 65px;
                                float: left;
                                margin-top: 10px;
                                .h21 {
                                    width: 100%;
                                    height: 26px;
                                    line-height: 26px;
                                    font-size: 18px;
                                }
                                .h22 {
                                    width: 100%;
                                    height: 39px;
                                    font-size: 13px;
                                    color: gray;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .hbBox4 {
        width: 100%;
        height: 704px;
        overflow: hidden;
        background: url("http://iv.okvu.cn/vugw/img/gb3.jpg");
        color: white;
        .hb4Top {
            width: 100%;
            height: 100px;
            margin-top: 30px;
            text-align: center;
            .h4t1 {
                width: 100%;
                height: 64px;
                line-height: 64px;
                font-size: 41px;
            }
            .t4l2 {
                width: 100%;
                height: 35px;
                line-height: 35px;
            }
        }
        .hb4List {
            max-width: 1200px;
            height: 541px;
            margin: 0 auto;
            margin-top: 20px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                .htsLi {
                    width: 286px;
                    height: 427px;
                    .hlTs {
                        width: 100%;
                        height: 164px;
                        margin-top: 40px;
                        .ht1 {
                            width: 100%;
                            height: 38px;
                            line-height: 38px;
                            font-size: 18px;
                        }
                        .ht2 {
                            width: 100%;
                            height: 126px;
                            font-size: 14px;
                        }
                    }
                    .hlImgs {
                        width: 286px;
                        height: 281px;
                        position: relative;
                        transition: 0.5s;
                        .hiBg {
                            width: 338px;
                            height: 228px;
                            margin-left: -30px;
                        }
                        .hiImg {
                            width: 286px;
                            height: 160px;
                            position: absolute;
                            top: 44px;
                            left: -3px;
                            border-bottom-left-radius: 10px;
                            border-bottom-right-radius: 10px;
                        }
                    }
                    .hlImgs:hover {
                        transform: translateY(-10px);
                    }
                }
            }
        }
    }
    .hbBox5 {
        width: 100%;
        height: 788px;
        overflow: hidden;
        .h5Box {
            max-width: 1280px;
            height: 650px;
            margin: 0 auto;
            margin-top: 100px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                .h5bLis {
                    width: 410px;
                    height: 288px;
                    background-color: #fff;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    border-radius: 10px;
                    transition: 0.5s;
                    .hlBox {
                        width: 90%;
                        height: 90%;
                        margin: 0 auto;
                        margin-top: 3%;
                        overflow: hidden;
                        .hb1 {
                            width: 91px;
                            height: 91px;
                            margin: 0 auto;
                            margin-top: 40px;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .hb2 {
                            width: 100%;
                            height: 52px;
                            line-height: 52px;
                            font-size: 24px;
                            margin-top: 10px;
                            text-align: center;
                        }
                        .hb3 {
                            width: 100%;
                            height: 29px;
                            line-height: 29px;
                            color: gray;
                            text-align: center;
                        }
                    }
                }
                .h5bLis:hover {
                    transform: translateY(-10px);
                }
                .h5bLib {
                    width: 824px;
                    height: 302px;
                    background-color: #fff;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    border-radius: 10px;
                    transition: 0.5s;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .h5bLib:hover {
                    transform: translateY(-10px);
                }
            }
        }
    }
    .hbBox6 {
        width: 100%;
        height: 800px;
        background: #4c547e;
        .hb6Box {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            overflow: hidden;
            .hb6Top {
                width: 100%;
                height: 107px;
                text-align: center;
                margin-top: 85px;
                .h6t1 {
                    width: 100%;
                    height: 47px;
                    line-height: 47px;
                    font-weight: bold;
                    font-size: 36px;
                    color: white;
                }
                .h6t2 {
                    width: 100%;
                    height: 60px;
                    line-height: 30px;
                    color: #dbdce5;
                    margin-top: 7px;
                }
            }
            .hb6Tb {
                width: 100%;
                height: 471px;
                margin-top: 60px;
                tbody {
                    width: 100%;
                    height: 100%;
                    .hb6Tr1 .h61td {
                        font-size: 30px;
                        line-height: normal;
                        text-align: center;
                        padding-left: 0;
                    }
                    .h61td:nth-child(1) {
                        width: 193px;
                    }
                    .hb6Tr .h6td {
                        border: 1px solid #747ca3;
                        line-height: 26px;
                        padding: 15px 0;
                        color: #fff;
                        padding-left: 40px;
                        .h6d {
                            width: 140px;
                            height: 20px;

                            overflow: hidden;
                            position: relative;
                            .h6dImg {
                                position: absolute;
                                top: -242px;
                                right: 58px;
                            }
                        }
                        .h6d2 {
                            width: 140px;
                            height: 20px;

                            overflow: hidden;
                            position: relative;
                            .h6dImg {
                                position: absolute;
                                top: -243px;
                                right: -158px;
                            }
                        }
                        .h6d3 {
                            width: 140px;
                            height: 20px;

                            overflow: hidden;
                            position: relative;
                            .h6dImg {
                                position: absolute;
                                top: -242px;
                                right: 0px;
                            }
                        }
                        .h6d4 {
                            width: 140px;
                            height: 20px;

                            overflow: hidden;
                            position: relative;
                            .h6dImg {
                                position: absolute;
                                top: -242px;
                                right: 28px;
                            }
                        }
                        .h6d5 {
                            width: 140px;
                            height: 20px;

                            overflow: hidden;
                            position: relative;
                            .h6dImg {
                                position: absolute;
                                top: -242px;
                                right: 0px;
                            }
                        }
                    }
                    td {
                        border: 1px solid #747ca3;
                        padding: 15px 0;
                        color: #fff;
                    }
                    td:nth-child(even) {
                        background: #5a628b;
                    }
                }
            }
        }
    }
}
</style>
